<template>
  <b-card
    title="Edit General Settings">
    
    <b-alert
      v-model="showDismissibleAlert"
      variant="danger"
      dismissible
      class="mb-1"
    >
      <div class="alert-body">
        {{error_message}}
      </div>
    </b-alert>
    <b-form @submit="formSubmit">
      <b-row v-for="(item,index) in items" :key="index">
        
        
        <b-col md="8" >
          <b-form-group
            :label="item.option_key | capitalize"
            class="required"
          >
            <b-form-input
              v-model="item.option_value"
            />
          </b-form-group>
        </b-col>

      </b-row>  

        

        <!-- submit and reset -->
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="warning"
            class="mr-1"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="$router.go(-1)"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>

  </b-card>

</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items:[],
      error_message:null,
      showDismissibleAlert:false
    }
  },
  methods : {
    
    editSettings(){
      return this.$store.dispatch(POST_API, {
           data:{
             type: 'default'
           },
           api: '/api/edit-setting'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                this.items = this.$store.getters.getResults.data;
                
                return this.items;
            }
        });
    },
    formSubmit(e){
      e.preventDefault();
      return this.$store.dispatch(POST_API, {
           data:{
             items:this.items
           },
           api: '/api/update-settings'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    this.$router.go(-1);
                });
                
            }
        });
    }
  },
  mounted(){
    this.editSettings();
  }
}
</script>
